import restaurant from "./images/camelot.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/n.jpg";
import imgheadermb from "./images/m7.png";
import imgheader from "./images/m2.png";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Gloria",
        familia: "fam. Talmaci",
        logo: logo,
        tata: "Sergiu",
        mama: "Tatiana",
        data: "11 August 2023",
        data_confirmare: "29 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "talmaci297@gmail.com", 
       tel: "+373 685 07 642",
       phone: "tel:+373685076427",
       viber: "viber://chat?number=%2B373685076427",
       whatsapp: "https://wa.me/+373685076427",
       messenger: "https://www.messenger.com/t/tania.codreanu.92",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Sfânta Treime",
            data: "11 august 2023, vineri, ora 14:00",
            adress: "Șoseaua Muncești 400, Chișinău",
            harta: "https://goo.gl/maps/r3RWHvdcHQoGtSki8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Camelot",
            data: "11 august 2023, vineri, ora 18:00",
            adress: "Tudor Bubuioc 2G, Bubuieci",
            harta: "https://goo.gl/maps/Pcyt1AYN1HQiyPu9A",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;